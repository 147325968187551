import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");
  require("./comparison-slider");

  $(".gallery__images").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  $(".gallery__images").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });

  $(".gallery-list").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  };

  $('.top__slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  });

  $('.timeline__year').on('click', function() {
    $('.timeline__year').removeClass('active'); 
    $(this).addClass('active'); 
    
    $('.timeline__content ul').css('left', '-'+ $(this).attr('id') + "vw");
    
  });

  setTimeout(() => {
    if ($('.cookie').length) {
      $('.footer-small').css('margin-bottom', '80px');
      $('.cookie__accept').on('click', function() {
        $('.footer-small').css('margin-bottom', '0');
      })
    }
  }, 1000);

  var imageContainer = document.querySelector("#top-image");
  if(imageContainer) {
    var image = imageContainer.querySelector("img");

    var range = document.querySelector("#range-input");
  
    range.oninput = function() {
      imageContainer.style.width = this.value + "%";
    }
  }


  $('.to-top').click(function() {
    $("html, body").animate({ scrollTop: "0" });
  });

});
